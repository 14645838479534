body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden auto;
}

#container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -html-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --tp-base-background-color: hsla(0, 0%, 10%, 0.80);
  --tp-base-shadow-color: hsla(0, 0%, 0%, 0.2);
  --tp-button-background-color: hsla(0, 0%, 80%, 1.00);
  --tp-button-background-color-active: hsla(0, 0%, 100%, 1.00);
  --tp-button-background-color-focus: hsla(0, 0%, 95%, 1.00);
  --tp-button-background-color-hover: hsla(0, 0%, 85%, 1.00);
  --tp-button-foreground-color: hsla(0, 0%, 0%, 0.80);
  --tp-container-background-color: hsla(0, 0%, 0%, 0.30);
  --tp-container-background-color-active: hsla(0, 0%, 0%, 0.60);
  --tp-container-background-color-focus: hsla(0, 0%, 0%, 0.50);
  --tp-container-background-color-hover: hsla(0, 0%, 0%, 0.40);
  --tp-container-foreground-color: hsla(0, 0%, 100%, 0.50);
  --tp-groove-foreground-color: hsla(0, 0%, 0%, 0.20);
  --tp-input-background-color: hsla(0, 0%, 0%, 0.30);
  --tp-input-background-color-active: hsla(0, 0%, 0%, 0.60);
  --tp-input-background-color-focus: hsla(0, 0%, 0%, 0.50);
  --tp-input-background-color-hover: hsla(0, 0%, 0%, 0.40);
  --tp-input-foreground-color: hsla(0, 0%, 100%, 0.50);
  --tp-label-foreground-color: hsla(0, 0%, 100%, 0.50);
  --tp-monitor-background-color: hsla(0, 0%, 0%, 0.30);
  --tp-monitor-foreground-color: hsla(0, 0%, 100%, 0.30);
}